<template>
  <div>
    <h1 class="pictures-title text-left">{{ $t("general.pictures") }}</h1>
    <div class="filters-container text-left">
      <TrekioInput id="search" v-model="filter.searchString" :placeholder="$t('general.search')" searchInput/>

      <TrekioSelect v-model="filter.countryCode" :text="filter.countryCode && allCountriesWithPictures.length > 0 ? allCountriesWithPictures.find(c => filter.countryCode == c.code)[countryLocalization] : $t('general.allFemAndLifeless')"
        :label="$t('inputLabels.countries')" searchInput @searchInput="(value) => countriesSearchString = value" :searchString="countriesSearchString"   
      >
        <TrekioSelectOption :value="null">{{ $t('general.allFemAndLifeless') }}</TrekioSelectOption>
        <TrekioSelectOption v-for="country in filteredCountries" :key="country.code" :value="country.code">{{ country[countryLocalization] }}</TrekioSelectOption>
      </TrekioSelect>

      <TrekioSelect v-if="$store.state.currentUserRole != 'AUTHOR'" v-model="filter.authorId" :label="$t('inputLabels.author')"
        :text="filter.authorId ? authorsList.find(author => author.id == filter.authorId) && authorsList.find(author => author.id == filter.authorId).userName : $t('general.allMascLiving')"
        :placeholder="$t('inputLabels.author')"
      >
        <TrekioSelectOption :value="null">{{ $t('general.allMascLiving') }}</TrekioSelectOption>
        <TrekioSelectOption v-for="author in authorsList" :key="author.id" :value="author.id">{{ author.userName }}</TrekioSelectOption>
      </TrekioSelect>

      <TrekioSelect :label="$t('inputLabels.assignedToTravelTip')" v-model="filter.assigned" :text="filter.assigned === null ? $t('general.allFemAndLifeless') : filter.assigned ? $t('filter.assigned') : $t('filter.notAssigned')">
        <TrekioSelectOption :value="null">{{ $t('general.allFemAndLifeless') }}</TrekioSelectOption>
        <TrekioSelectOption :value="true">{{ $t('filter.assigned') }}</TrekioSelectOption>
        <TrekioSelectOption :value="false">{{ $t('filter.notAssigned') }}</TrekioSelectOption>
      </TrekioSelect>
      
    </div>
      <p class="text-left" v-if="pictures && pictures.length === 0 && !isLoading">{{ $t("filter.noResults") }}</p>
      <PictureList v-else-if="pictures && !hasFilterChanged" :pictures="pictures"/>
      <div v-if="isLoading || showMoreEnabled" class="page-loading-container">
        <div v-if="isLoading" class="loader-1 center paging-loading"><span></span></div>
        <TrekioButton v-else-if="showMoreEnabled && !isLoading" secondary @click="showMore">{{ $t("general.loadMore") }}</TrekioButton>
      </div>
      <router-link v-if="!this.pictures || this.pictures.length < 1" to='/admin/managePicture' style="position: absolute; bottom: 0; right: 80px">
        <div class="plus"></div>
      </router-link>
  </div>
</template>

<script>

  import {PICTURES, PICTURES_ADMIN_BY_AUTHOR_ID, PICTURES_FOR_COUNTRIES} from "@/api/graphql/query/PictureQuery"
  import {CLEVERTRIP_IMG_URL}  from '@/definitions';
  import PictureList from "@/components/admin/PictureList";
  import sharedUtils from '@/utils/sharedUtils.js'

  const SINGLE_PAGE_SIZE = 40

  export default {
    name: "PictureListPage",
    components: {PictureList},

    data: function () {
        return {
            imgURL: CLEVERTRIP_IMG_URL,
            pictures: [],
            picturesToGetCountries: [],
            countriesSearchString: '',
            filter: {
                searchString: null,
                countryCode: null,
                assigned: null,
                authorId: this.$store.state.currentUserRole === "AUTHOR" ? this.$store.state.currentUserId : null
            },
            pageSize: {
              page: 0,
              size: SINGLE_PAGE_SIZE,
              sortAttribute: "LENGTH_OF_STAY",
              sortDirection: "ASC"
            },
            showMoreEnabled: true,
            hasFilterChanged: true,
            userId: null,
        };
    },
    created() {
      this.userId= this.$store.state.currentUserId
    },
    beforeRouteLeave(to, from, next) {
      if (this.$store.getters.getLoggingOut == false) this.$store.commit('setPicturesFilter', this.filter)
      next()
    },
    mounted() {
      if (this.$store.getters.getPicturesFilter != null) this.filter = this.$store.getters.getPicturesFilter
    },

    methods: {
      showMore () {
        this.pageSize.page++

        this.$apollo.queries.pictures.fetchMore({
          variables: {
            filter: this.filter,
            pageSize: this.pageSize
          },

          updateQuery: (previousResult, { fetchMoreResult }) => {
            if (fetchMoreResult.pictures.length < SINGLE_PAGE_SIZE) {
              this.showMoreEnabled = false
            }

            return {
              pictures: [
                ...previousResult.pictures,
                ...fetchMoreResult.pictures,
              ],
            }
          },
        })
      },
    },
    computed: {
        isLoading() {
          return this.$apollo.queries.pictures.loading
        },
        authorsList: function () {
          if (!this.picturesToGetCountries) return []

          const authors = [];
          this.picturesToGetCountries.forEach((picture) => {
            if (picture.author) {
              if (!authors.some(author => author.id == picture.author.is)) {
                authors.push(picture.author)
              }
            }
          });

          return [...new Set(authors)];
        },
        allCountriesWithPictures() {
          if (!this.picturesToGetCountries && !this.pitures) return []
          let allCountries = [];
          this.picturesToGetCountries.forEach(pic => {
              if (!allCountries.some(value => value.code === pic.country.code)) {
                  allCountries.push(pic.country);
              }
          });
          return allCountries.sort((a, b) => {
            return sharedUtils.removeAccents(a[this.countryLocalization]).localeCompare(sharedUtils.removeAccents(b[this.countryLocalization]), this.$store.state.preferredLanguage)
          });
        },
        filteredCountries() {
          if (!this.allCountriesWithPictures) return []
          if (this.countriesSearchString === '') return this.allCountriesWithPictures
          const searchString = sharedUtils.removeAccents(this.countriesSearchString.toUpperCase())
          return this.allCountriesWithPictures.filter(c => {
            if (sharedUtils.removeAccents(c[this.countryLocalization]).toUpperCase().includes(searchString)) return true
            return false
          })
        },
        countryLocalization() {
          return this.$store.getters.preferredLanguage == "en" ? "nameEnglish" : "nameCzech"
        }
    },

    watch: {
      filter: {
        handler(newValue, oldValue) {
          this.pageSize.page = 0
          this.hasFilterChanged = true
          this.showMoreEnabled = true
        },
        deep: true
      }
    },

    apollo: {
        pictures: {
            query: PICTURES,

            variables: function () {
                return {
                    filter: {
                        searchString: this.filter.searchString,
                        countryCode: this.filter.countryCode,
                        assigned: this.filter.assigned,
                        authorId: this.$store.state.currentUserRole == "AUTHOR" ? this.$store.state.currentUserId : this.filter.authorId
                      },
                      pageSize: {
                        page: 0,
                        size: SINGLE_PAGE_SIZE,
                        sortAttribute: "LENGTH_OF_STAY",
                        sortDirection: "ASC"
                      },
                }
            },
            result({data}) {
              if (data.pictures.length < SINGLE_PAGE_SIZE) {
                this.showMoreEnabled = false
              }
              this.hasFilterChanged = false
            },
            fetchPolicy: 'cache-and-network',
            skip: function () {
              return !this.userId
            }
        },
        picturesToGetCountries: {
          query: function() {
            return this.$store.state.currentUserRole == 'AUTHOR' ? PICTURES_ADMIN_BY_AUTHOR_ID : PICTURES_FOR_COUNTRIES
          },
          variables: function() {
            if (this.$store.state.currentUserRole === 'AUTHOR') return {userId: this.$store.state.currentUserId}
          },

          skip: function() {
            return !this.$store.state.currentUserRole 
          }
        }
    },
}
</script>

<style scoped lang="scss">

  .page-loading-container {
    max-width: 1440px;
    margin-top: 30px
  }
  .filters-container {
    padding-bottom: 50px;
    gap: 30px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, auto));
  }
</style>